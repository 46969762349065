import React from "react"
import Layout from "../../components/layout"

const ServicePage = () => {
    return (
        <Layout>
            <div className="mx-auto container px-4">
                <h1>Library Management</h1>
                <hr className="mb-4" />
                <p>Under Construction</p>
            </div>
        </Layout>
    )
}

export default ServicePage
